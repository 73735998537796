import React from "react";
import {HTTP_METHODS, httpRequest} from "../../services/httpService";
import {bake} from "../../helpers/toaster";
import Table from "../../Components/Dashboard/Table";
import {Link} from "react-router-dom";
import Loading from "../../Components/Dashboard/Loading";

const Messages = () => {
    const [messages, setMessages] = React.useState([]);
    const [isLoading, setLoading] = React.useState(true);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Phone',
                accessor: 'phone',
            },
            {
                Header: 'Date',
                accessor: 'createdAt',
            },
            {
                Header: 'Message',
                accessor: (message => (
                    message.message.slice(0, 40) + '...'
                )),
            }, {
                Header: 'View',
                accessor: (message) => (
                    <Link to={`/clients/messages/${message.id}`}
                          className={"btn btn-primary"}>
                        View
                    </Link>
                ),
            }
        ],
        []);

    React.useEffect(() => {
        httpRequest(
            `${process.env.REACT_APP_API_URI}/messages`,
            HTTP_METHODS.GET)
            .then(({data}) => {
                setMessages(data)
            })
            .catch(err => {
                const msg = err.hasOwnProperty('response') ? err.response.data : err;
                bake(msg, '#ff0000');
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    return (
        <div className="container-fluid mt--6">
            <div className="row">
                <div className="col">
                    <div className="card">
                        {isLoading &&
                        (
                            <div className="text-center my-auto">
                                <Loading/>
                            </div>
                        )}
                        {!isLoading &&
                        (<React.Fragment>
                            <div className="card-header border-0">
                                <h3 className="mb-0">Customer Messages</h3>
                                <small>Contact Us</small>
                            </div>
                            <div className="table-responsive">
                                <Table columns={columns} data={messages}/>
                            </div>
                        </React.Fragment>)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Messages;
