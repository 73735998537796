import React from "react";
import {useParams} from 'react-router-dom';
import {httpRequest, HTTP_METHODS} from "../../services/httpService";
import {bake} from "../../helpers/toaster";
import Loading from "../../Components/Dashboard/Loading";

const Message = (props) => {
        const [userRequest, setMessage] = React.useState({});
        const [isLoading, setLoading] = React.useState(true);

        const {id} = useParams();

        React.useEffect(() => {
                setMessage(prevState => ({...prevState, loading: true}));
                httpRequest(`${process.env.REACT_APP_API_URI}/messages/${id}`, HTTP_METHODS.GET)
                    .then(({data}) => {
                        setMessage(data);
                    }).catch(e => {
                    const msg = e.response ? e.response.data : e;
                    if (e.response && e.response.status === 404) {
                        // redirect to 404
                        setTimeout(() => {
                            props.history.push('/404?back=messages');
                        }, 300);
                    } else if (e === 'Network Error') {
                        setTimeout(() => {
                            props.history.push('/errors/network?back=messages');
                        }, 300);
                    }

                    bake(msg, '#ff0000');
                }).finally(() => {
                    setLoading(false);
                });
            }, [id, props.history]
        );

        const {name, phone, createdAt, email, message} = userRequest;
        return (
            <div className="container-fluid mt--6">}
                <div className="row">
                    <div className="col">
                        <div className="card min-vh-100">
                            {isLoading &&
                            (
                                <div className="text-center my-auto">
                                    <Loading/>
                                </div>
                            )}
                            {!isLoading &&
                            (
                                <React.Fragment>
                                    <div className="card-header border-0">
                                        <div className="row align-content-between">
                                            <div className="col-6">
                                                <h3 className="mb-0">{name} 's Message</h3>
                                                <small>Contact Us</small>
                                            </div>
                                            <div className="col-6 text-right">
                                                <h5 className="text-darker">
                                                    {phone}
                                                </h5>
                                                <h5 className="text-blue">
                                                    {email}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <h3>
                                        <span className="badge badge-info">
                                            {new Date(createdAt).toDateString()}
                                        </span>
                                        </h3>
                                        <p>
                                            {message}
                                        </p>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
;

export default Message;