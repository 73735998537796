import React from 'react';
import {bake} from "../../helpers/toaster";
import {HTTP_METHODS, httpRequest} from "../../services/httpService";

const validEmailRegex =
    // eslint-disable-next-line no-useless-escape
    RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            formValid: false,
            errors: {
                email: '',
                password: '',
            },
            loading: false
        };
        this.login = this.login.bind(this);
    }

    componentDidMount() {
        let token = localStorage.getItem('token');
        // check if token is valid
        if (token) {
            httpRequest(`${process.env.REACT_APP_API_URI}/users/authorize`, HTTP_METHODS.POST)
                .then(({data}) => {
                    localStorage.setItem('user', JSON.stringify(data));
                    // redirect to home page
                    this.props.history.push('/');
                })
                .catch(err => {
                    const msg = err.response.data ? err.response.data : err;
                    bake(msg);
                    console.error(err)
                });
        }
    }

    changeListener(e) {
        // Validate fields
        const {value, name} = e.target;
        let errors = this.state.errors;

        switch (name) {
            case 'email':
                errors.email = (validEmailRegex.test(value) && !!value) ? '' : 'Email is not valid!';
                break;
            case 'password':
                errors.password = (value.length > 6 && !!value) ? '' : 'Password is not valid';
                break;
            default:
                break;
        }

        this.setState({
            [name]: value,
        });
    }

    validateForm() {
        let {email, password} = this.state;
        return (email && password);
    }

    login(e) {
        e.preventDefault();
        const {email, password, errors} = this.state;

        if (this.validateForm(errors) && (email && password)) {
            // Set loading
            this.setState(prevState => ({
                ...prevState,
                loading: true
            }));

            httpRequest(`${process.env.REACT_APP_API_URI}/users/login`, HTTP_METHODS.POST, {email, password})
                .then(({data}) => {
                    localStorage.setItem('token', data.token);
                    this.props.history.push('/');
                })
                .catch(err => {
                    const msg = err.hasOwnProperty('response') ? err.response.data : err;
                    bake(msg, '#ff0000');
                })
                .finally(() => {
                    // Set loading
                    this.setState(prevState => ({
                        ...prevState,
                        loading: false
                    }));
                });
        } else {
            bake('Please enter your credentials', '#ff0000');
        }
    }

    render() {
        let {errors, email, password, loading} = this.state;
        return (
            <div className="main-content">
                {/*Header*/}
                <div className="header bg-gradient-secondary py-7 py-lg-8 pt-lg-9">
                    <div className="container">
                        <div className="header-body text-center mb-7">
                            <div className="row justify-content-center">
                                <div className="col-xl-5 col-lg-6 col-md-8 px-5">
                                    <h1 className="display-3">Welcome!</h1>
                                    <p className="text-lead text-black-50">Use your email and password to login.</p>
                                    <p className="text-black-50 font-weight-bold">Only authorized users.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                             xmlns="http://www.w3.org/2000/svg">
                            <polygon className="fill-default" points="2560 0 2560 100 0 100"/>
                        </svg>
                    </div>
                </div>
                {/*Page content*/}
                <div className="container mt--8 pb-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-7">
                            <div className="card bg-secondary border-0 mb-0">
                                <div className="card-header bg-transparent pb-5">
                                    <div className="text-muted text-center mt-2 mb-3"><small>Sign in</small></div>
                                </div>
                                <div className="card-body px-lg-5 py-lg-5">
                                    <form action="#" method="POST" onSubmit={this.login}>
                                        <div className="form-group mb-3">
                                            <div className="input-group input-group-merge input-group-alternative">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i
                                                        className="far fa-envelope"/></span>
                                                </div>
                                                <input placeholder="Email" type="email"
                                                       name="email" className="form-control"
                                                       onChange={(e) => this.changeListener(e)}
                                                       value={email}/>
                                            </div>
                                            <small className={"text-danger"}>{errors.email}</small>
                                        </div>
                                        <div className="form-group">
                                            <div className="input-group input-group-merge input-group-alternative">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fas fa-key"/></span>
                                                </div>
                                                <input className="form-control" placeholder="Password" type="password"
                                                       autoComplete="false" name="password"
                                                       onChange={(e) => this.changeListener(e)}
                                                       value={password}/>
                                            </div>
                                            <small className={"text-danger"}>{errors.password}</small>
                                        </div>
                                        <div className="text-center">
                                            <button type="submit" className="btn btn-primary my-4" disabled={loading}
                                                    onClick={this.login}>Sign in
                                            </button>
                                            <div>
                                                {loading && (
                                                    <div className="spinner-grow text-dark spinner-small" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>)
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <button onClick={() => alert('Contact system admin')}
                                            className="text-light btn-no-style">
                                        <small>Forgot password?</small>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;