import React from "react";
import {Line} from 'react-chartjs-2';

export const LineChart = ({data}) => {
    const chartData = {
        labels: data.labels,
        datasets: [
            {
                label: 'Total Messages Per Month',
                data: data.vals,
                fill: false,
                backgroundColor: 'rgb(255,25,61)',
                borderColor: 'rgb(255,25,61)',
                borderCapStyle: 'butt',
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgb(255,25,61)',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgb(255,25,61)',
                pointHoverBorderColor: 'rgb(0,0,0)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
            }
        ],
    };

    const lineChartOptions = {
        legend: false,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                ticks: {
                    min: 0,
                    stepSize: 1,
                    fontColor: "white",
                }
            }],
            yAxes: [{
                ticks: {
                    min: 0,
                    stepSize: 1,
                    fontColor: "white",
                }
            }]
        }
    };

    return (
        <div className="chart"
             style={{
                 height: '300px'
             }}
        >
            <Line
                data={chartData}
                width={100}
                height={50}
                options={lineChartOptions}
            />
        </div>
    );
};
