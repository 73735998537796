import React from "react";

const Footer = () => (
    <footer className="py-5" id="footer-main">
        <div className="container">
            <div className="row align-items-center justify-content-xl-between">
                <div className="col-xl-6">
                    <div className="copyright text-center text-xl-left text-muted">
                        © {new Date().getFullYear()}
                        <a href="https://toyotamas.ly" rel="noopener noreferrer"
                           className="font-weight-bold ml-1" target="_blank">MAS Motors
                            LLC.</a>
                    </div>
                </div>
                <div className="col-xl-6">
                    <ul className="nav nav-footer justify-content-center justify-content-xl-end">
                        <li className="nav-item d-flex">
                            <span>Developed by</span>
                            <a href="https://www.github.com/abdelsalamshahlol" className="nav-link" target="_blank"
                               rel="noopener noreferrer">
                                <i className="fab fa-github-square fa-2x"/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;