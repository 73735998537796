import React from "react";
import {useParams} from 'react-router-dom';
import Loading from "../../../Components/Dashboard/Loading";
import {HTTP_METHODS, httpRequest} from "../../../services/httpService";
import {bake} from "../../../helpers/toaster";

export default (props) => {
    const {id} = useParams();
    const [isLoading, setLoading] = React.useState(false);
    const [media, setMedia] = React.useState([{
        file: null,
        type: '#',
        cat: null,
        errors: {}
    }]);

    const changeListener = (e) => {
        e.persist();
        const {value, type} = e.target;
        const mediaType = e.target.getAttribute('data-media-type');
        const index = e.target.getAttribute('data-parent-index');
        const newMedia = media.slice(0);

        if (type === 'file') {
            newMedia[index][mediaType] = e.target.files[0];
        } else {
            newMedia[index][mediaType] = value;
            if (mediaType === 'cat') {
                newMedia[index]['type'] = 'N/A';
            }
        }
        setMedia(newMedia);
    };

    const addMedia = () => {
        const mediaObj = {
            file: null,
            type: '#',
            cat: null,
            errors: {}
        };

        const newMedia = media.slice(0);
        newMedia.push(mediaObj);

        setMedia(newMedia);
    };

    const removeLastRow = () => {
        const newMedia = media.slice(0, media.length - 1);
        setMedia(newMedia);
    };

    const saveMedia = (e) => {
        e.preventDefault();

        if (!mediaValid()) {
            return bake('Invalid input', '#ff0000');
        }

        // Build form data
        let formData = new FormData();

        for (let key in media) {
            formData.append(`media[cat]`, media[key]['cat']);
            formData.append(`media[type]`, media[key]['type']);
            formData.append(`media[file][${key}]`, media[key]['file']);
        }
        formData.append('id', id);

        // Set blocker
        setLoading(true);
        // Request
        httpRequest(`${process.env.REACT_APP_API_URI}/vehicles/media/add`,
            HTTP_METHODS.POST,
            formData, {'Content-Type': 'multipart/form-data'})
            .then((data) => {
                props.history.push(`/models/${id}`);
                bake('Media added', '#0275d8');
            })
            .catch(err => {
                if (err.response.status === 422) {
                    const errors = err.response.data.errors;
                    setMedia(prevState => (
                        {
                            ...prevState,
                            errors
                        }
                    ));
                    return bake('Invalid input', '#ff0000');
                }

                console.error(err);
                const msg = err.hasOwnProperty('response') ? err.response.data : err;
                bake(msg, '#ff0000');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const mediaValid = () => {
        let valid = true;

        const newMedia = media.map((_media) => {
            for (const prop in _media) {
                if (prop !== 'errors') {
                    if (!_media[prop] || _media[prop] === '#') {
                        valid = false;
                        _media['errors'][prop] = {msg: 'Invalid value'}
                    } else {
                        _media['errors'][prop] = null;
                    }
                }
            }

            return _media;
        });
        setMedia(newMedia);
        return valid;
    };

    return (
        <div className="container-fluid mt--6">
            <div className="row">
                <div className="col">
                    <div className="card">
                        {isLoading &&
                        (
                            <div className="text-center my-auto">
                                <Loading/>
                            </div>
                        )}
                        {!isLoading && (
                            <>
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-3">
                                            <h3 className="mb-0">Media & Attachments</h3>
                                        </div>
                                        <div className="col text-right">
                                            <button className="btn btn-primary" type="button"
                                                    onClick={addMedia}>Add
                                                Media
                                            </button>
                                            <button className="btn btn-primary" onClick={removeLastRow}>
                                                <i className="fa fa-trash"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={saveMedia}>
                                        {
                                            media.map((m, i) => {
                                                return (<div className="pl-lg-4" key={i}>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label"
                                                                       htmlFor="input-username">Media Category</label>
                                                                <select name="media" data-media-type="cat"
                                                                        defaultValue={'#'}
                                                                        data-parent-index={i}
                                                                        className="form-control"
                                                                        onChange={changeListener}>
                                                                    <option value="#" disabled/>
                                                                    {['Download', 'Gallery'].map(category => (
                                                                        <option value={category}
                                                                                key={category}>{category}</option>))
                                                                    }
                                                                </select>
                                                                {media[i].errors.cat &&
                                                                <small
                                                                    className="text-danger">{media[i].errors.cat.msg}</small>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label"
                                                                       htmlFor="input-username">Media Type</label>
                                                                <select name="media" data-media-type="type"
                                                                        data-parent-index={i}
                                                                        className="form-control"
                                                                        onChange={changeListener}
                                                                        value={media[i].type}>
                                                                    <option value="#" disabled/>
                                                                    {
                                                                        (media[i].cat === 'Download') ? (
                                                                                <option value={'N/A'}>N/A</option>)
                                                                            : (
                                                                                <>
                                                                                    <option value={'Interior'}>Interior
                                                                                    </option>
                                                                                    <option value={'Exterior'}>Exterior
                                                                                    </option>
                                                                                </>
                                                                            )

                                                                    }
                                                                </select>
                                                                {media[i].errors.type &&
                                                                <small
                                                                    className="text-danger">{media[i].errors.type.msg}</small>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label"
                                                                       htmlFor="input-username">Media File</label>
                                                                <div className="custom-file">
                                                                    <input type="file" className="custom-file-input"
                                                                           name="media"
                                                                           data-media-type="file" data-parent-index={i}
                                                                           onChange={changeListener}
                                                                           style={{visibility: 'unset'}}
                                                                           accept=".png, .jpg, .jpeg"/>
                                                                    <label className="custom-file-label"
                                                                           htmlFor="customFile">Choose
                                                                        file</label>
                                                                </div>
                                                                {media[i].errors.file &&
                                                                <small
                                                                    className="text-danger">{media[i].errors.file.msg}</small>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr/>
                                                </div>)
                                            })
                                        }

                                        <div>
                                            <button className="btn btn-primary">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </>)}
                    </div>
                </div>
            </div>
        </div>
    );
}
;