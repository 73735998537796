import React from "react";
import Loading from "../../Components/Dashboard/Loading";
import {bake} from "../../helpers/toaster";
import {useParams} from 'react-router-dom';
import {HTTP_METHODS, httpRequest} from "../../services/httpService";

export default (props) => {
    const [loading, setLoading] = React.useState(true);
    const {id} = useParams();
    const [form, setForm] = React.useState({
        firstName: '',
        lastName: '',
        title: '',
        email: '',
        password: null,
        status: 'Active',
        isAdmin: false,
        errors: {
            firstName: null,
            lastName: null,
            title: null,
            email: null,
            password: null,
        }
    });

    React.useEffect(() => {
        httpRequest(`${process.env.REACT_APP_API_URI}/users/${id}`, HTTP_METHODS.GET)
            .then(({data}) => {
                setForm(prevState => ({
                    ...prevState,
                    ...data
                }));
            })
            .catch(err => {
                console.error(err);
                const msg = err.hasOwnProperty('response') ? err.response.data : err;
                bake(msg, '#ff0000');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    const changeListener = (e) => {
        let {name, value} = e.target;
        // This is used to convert the values from string to boolean
        if (name === 'isAdmin') {
            value = value === '1';
        }

        setForm(prevState => ({
                ...prevState,
                [name]: value
            })
        );
    };

    const updateUser = () => {
        // Set blocker
        setLoading(true);
        // Request
        httpRequest(`${process.env.REACT_APP_API_URI}/users`, HTTP_METHODS.PATCH, form)
            .then((data) => {
                // Show success message for the user
                bake('User updated', '#0275d8');
                props.history.push(`/users/${id}`);
            })
            .catch(err => {
                if (err.response.status === 422) {
                    const errors = err.response.data.errors;
                    setForm(prevState => (
                        {
                            ...prevState,
                            errors
                        }
                    ));
                    return bake('Invalid input', '#ff0000');
                }

                console.error(err);
                const msg = err.hasOwnProperty('response') ? err.response.data : err;
                bake(msg, '#ff0000');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className="container-fluid mt--6">
                <div className="row">
                    <div className="col">
                        <div className="card">
                            {loading &&
                            (
                                <div className="text-center my-auto">
                                    <Loading/>
                                </div>
                            )}
                            {!loading &&
                            (<React.Fragment>
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col-8">
                                            <h3 className="mb-0">MAS Motors LLC API User</h3>
                                            <small>Access the system features and control the API.</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form method="POST">
                                        <h6 className="heading-small text-muted mb-4">User information</h6>
                                        <div className="pl-lg-4">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label"
                                                               htmlFor="input-username">First Name</label>
                                                        <input type="text" className="form-control" name="firstName"
                                                               onChange={changeListener}
                                                               value={form.firstName}/>
                                                        {form.errors.firstName &&
                                                        <small
                                                            className="text-danger">{form.errors.firstName.msg}</small>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label"
                                                               htmlFor="input-username">Last Name</label>
                                                        <input type="text" className="form-control" name="lastName"
                                                               onChange={changeListener}
                                                               value={form.lastName}/>
                                                        {form.errors.lastName &&
                                                        <small
                                                            className="text-danger">{form.errors.lastName.msg}</small>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label"
                                                               htmlFor="input-username">Email</label>
                                                        <input type="email" className="form-control" name="email"
                                                               onChange={changeListener}
                                                               value={form.email}/>
                                                        {form.errors.email &&
                                                        <small
                                                            className="text-danger">{form.errors.email.msg}</small>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label"
                                                               htmlFor="input-username">Password</label>
                                                        <input type="password" className="form-control" name="password"
                                                               onChange={changeListener}
                                                               value={form.password}/>
                                                        <small className="text-muted">Password must be at least 6
                                                            characters long.</small>
                                                        {form.errors.password &&
                                                        <small
                                                            className="text-danger">{form.errors.password.msg}</small>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="form-group">
                                                        <label className="form-control-label"
                                                               htmlFor="input-username">Title</label>
                                                        <input type="text" className="form-control" name="title"
                                                               onChange={changeListener} placeholder="Head of sales"
                                                               value={form.title}/>
                                                        {form.errors.title &&
                                                        <small
                                                            className="text-danger">{form.errors.title.msg}</small>}
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label className="form-control-label">Status</label>
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input type="radio" name="status"
                                                                   value="Active" onChange={changeListener}
                                                                   className="custom-control-input"
                                                                   id="activeRadio"
                                                                   checked={form.status === 'Active'}/>

                                                            <label className="custom-control-label"
                                                                   htmlFor="activeRadio">Active</label>
                                                        </div>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" id="disabledRadio" name="status"
                                                                   value="Disabled" onChange={changeListener}
                                                                   className="custom-control-input"
                                                                   checked={form.status === 'Disabled'}/>

                                                            <label className="custom-control-label"
                                                                   htmlFor="disabledRadio">Disabled</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label className="form-control-label">Role</label>
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input type="radio" name="isAdmin"
                                                                   value={1} onChange={changeListener}
                                                                   className="custom-control-input"
                                                                   id="adminRadio"
                                                                   checked={form.isAdmin === true}/>

                                                            <label className="custom-control-label"
                                                                   htmlFor="adminRadio">Admin</label>
                                                        </div>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" id="userRadio" name="isAdmin"
                                                                   value={0} onChange={changeListener}
                                                                   className="custom-control-input"
                                                                   checked={form.isAdmin === false}/>

                                                            <label className="custom-control-label"
                                                                   htmlFor="userRadio">Normal</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </React.Fragment>)
                            }
                            <div className="card-footer">
                                <div className="row">
                                    <div className="col-12">
                                        <button className="btn btn-primary" onClick={updateUser}>Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}