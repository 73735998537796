import React from 'react';
import {httpRequest, HTTP_METHODS} from "../../services/httpService";
import {bake} from "../../helpers/toaster";
import {LineChart} from "../../Components/Dashboard/Charts";
import Loading from "../../Components/Dashboard/Loading";

const Index = () => {
    const [stats, setStats] = React.useState({message: null, booking: null});
    const [isLoading, setLoading] = React.useState(true);

    React.useEffect(() => {
        httpRequest(`${process.env.REACT_APP_API_URI}/stats`, HTTP_METHODS.GET)
            .then(({data}) => {
                setStats(data);
            })
            .catch(e => {
                console.error(e);
                const msg = e.response ? e.response.data : e;
                bake(msg, '#ff0000');
            })
            .finally(() => setLoading(false));
    }, []);

    const MessageStats = () => {
        const {message} = stats;
        return (
            <p className="mt-3 mb-0 text-sm">
                <span
                    className={`mr-2 ${message.msgChange.isUp ? 'text-success' : 'text-danger'}`}>
                <i className={`fa ${message.msgChange.isUp ? 'fa-arrow-up' : 'fa-arrow-down'}`}/>
                    {message.msgChange.val}%</span>
                <span className="text-nowrap">Since last month</span>
            </p>
        )
    };

    const BookingStats = () => {
        const {booking} = stats;
        return (
            <p className="mt-3 mb-0 text-sm">
                    <span className={`mr-2 ${booking.bookingChange.isUp ? 'text-success' : 'text-danger'}`}>
                        <i className={`fa ${booking.bookingChange.isUp ? 'fa-arrow-up' : 'fa-arrow-down'}`}/>
                        {booking.bookingChange.val}%</span>
                <span className="text-nowrap">Since last month</span>
            </p>
        );
    };

    const messagesChart = stats.message ? stats.message.msgPerMonth : [];
    const bookingsChart = stats.booking ? stats.booking.bookingPerMonth : [];

    return (
        <div className="container-fluid mt--6">
            <div className="row">
                <div className="col">
                    <div className="card">
                        {isLoading &&
                        (
                            <div className="text-center my-auto">
                                <Loading/>
                            </div>
                        )}
                        {!isLoading &&
                        (<React.Fragment>
                            <div className="card-header border-0">
                                <h3 className="mb-0">MAS Motors {new Date().getFullYear()} stats</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-3 col-md-6">
                                        <div className="card card-stats">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <h5 className="card-title text-uppercase text-muted mb-0">Messages</h5>
                                                        <span className="h2 font-weight-bold mb-0">{
                                                            stats.message && stats.message.total
                                                        }</span>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div
                                                            className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                            <i className="fa fa-envelope"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="mt-3 mb-0  text-sm">
                                                    <span className="text-nowrap">In total this year</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-6">
                                        <div className="card card-stats">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <h5 className="card-title text-uppercase text-muted mb-0">Bookings</h5>
                                                        <span className="h2 font-weight-bold mb-0">
                                                        {stats.booking && stats.booking.total}
                                                    </span>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div
                                                            className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                                            <i className="far far fa-calendar"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="mt-3 mb-0  text-sm">
                                                    <span className="text-nowrap">In total this year</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-6">
                                        <div className="card card-stats">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <h5 className="card-title text-uppercase text-muted mb-0">Messages</h5>
                                                        <span
                                                            className="h2 font-weight-bold mb-0">{stats.message !== null && stats.message.twoMonths !== null ? stats.message.twoMonths[1].count : 0}</span>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div
                                                            className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                            <i className="far fa-envelope"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {stats.message && <MessageStats/>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-6">
                                        <div className="card card-stats">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <h5 className="card-title text-uppercase text-muted mb-0">Bookings</h5>
                                                        <span
                                                            className="h2 font-weight-bold mb-0">{stats.booking !== null && stats.booking.twoMonths !== null ? stats.booking.twoMonths[1].count : 0}</span>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div
                                                            className="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                                                            <i className="fa fa-calendar-day"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                {stats.booking && <BookingStats/>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>)}
                    </div>
                </div>
            </div>
            {!isLoading && (
                <div className="row">
                    <div className="col">
                        <div className="card bg-dark">
                            <div className="card-header bg-transparent">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 className="text-light text-uppercase ls-1 mb-1">Overview</h6>
                                        <h5 className="h3 text-white mb-0">Messages Per Month</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    stats.message && (
                                        <LineChart data={messagesChart}/>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card bg-gradient-blue">
                            <div className="card-header bg-transparent">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 className="text-light text-uppercase ls-1 mb-1">Overview</h6>
                                        <h5 className="h3 text-white mb-0">Bookings Per Month</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    stats.message && (
                                        <LineChart data={bookingsChart}/>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Index;