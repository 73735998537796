import React from "react";
import {Link, useParams} from 'react-router-dom';
import Loading from "../../Components/Dashboard/Loading";
import {Modal, Accordion, Card, Carousel} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {HTTP_METHODS, httpRequest} from "../../services/httpService";
import {bake} from "../../helpers/toaster";

export default (props) => {
    const {id} = useParams();
    const [loading, setLoading] = React.useState(true);
    const [model, setModel] = React.useState({name: '', cat: ''});
    const [show, setShow] = React.useState(false);
    const [delAction, setDelAction] = React.useState(null);

    const handleClose = () => setShow(false);
    const handleShow = (type, id = null) => {
        setDelAction({type, id});
        setShow(true);
    };

    const handleDelete = () => {
        switch (delAction.type) {
            case 'model':
                setLoading(true);
                httpRequest(`${process.env.REACT_APP_API_URI}/vehicles`, HTTP_METHODS.DELETE, {id})
                    .then(({data}) => {
                        setTimeout(() => {
                            props.history.push('/models');
                        }, 500);
                    })
                    .catch(e => {
                        let msg;
                        if (e.response.status === 422) {
                            msg = 'Invalid request';
                        } else {
                            msg = e.response ? e.response.data : e;
                        }
                        bake(msg, '#ff0000');
                    });
                break;

            case 'media':
                setLoading(true);
                httpRequest(`${process.env.REACT_APP_API_URI}/vehicles/media/delete`, HTTP_METHODS.DELETE, {id: delAction.id})
                    .then(({data}) => {
                        // Remove media from the array
                        const newMedia = model.media.filter(media => media.id !== delAction.id);
                        setModel(prevState => ({
                            ...prevState,
                            media: newMedia
                        }));

                        bake('Media removed');
                    })
                    .catch(e => {
                        let msg;
                        if (e.response.status === 422) {
                            msg = 'Invalid request';
                        } else {
                            msg = e.response ? e.response.data : e;
                        }
                        bake(msg, '#ff0000');
                    })
                    .finally(() => {
                        setLoading(false);
                        setShow(false)
                    });
                break;
            case 'variant':
                setLoading(true);
                // Send HTTP request to sever
                httpRequest(`${process.env.REACT_APP_API_URI}/vehicles/variant/delete`, HTTP_METHODS.DELETE, {id: delAction.id})
                    .then(() => {
                        // Remove variant from the array
                        const newVariants = model.variants.filter(variant => variant.id !== delAction.id);
                        setModel(prevState => ({
                            ...prevState,
                            variants: newVariants
                        }));

                        bake('Variant removed');
                    })
                    .catch(e => {
                        let msg;
                        if (e.response.status === 422) {
                            msg = 'Invalid request';
                        } else {
                            msg = e.response ? e.response.data : e;
                        }
                        bake(msg, '#ff0000');
                    })
                    .finally(() => {
                        setLoading(false);
                        setShow(false)
                    });
                break;
            default:
                break;
        }
    };

    React.useEffect(() => {
        httpRequest(`${process.env.REACT_APP_API_URI}/vehicles/${id}`, HTTP_METHODS.GET)
            .then(({data}) => {
                setModel(data);
            })
            .catch(e => {
                const msg = e.response ? e.response.data : e;
                if (e.response && e.response.status === 404) {
                    // redirect to 404
                    setTimeout(() => {
                        props.history.push('/404?back=models');
                    }, 300);
                } else if (e === 'Network Error') {
                    setTimeout(() => {
                        props.history.push('/errors/network?back=models');
                    }, 300);
                }
                bake(msg, '#ff0000');
            }).finally(() => {
            setLoading(false);
        });
    }, [id, props.history]);


    return (
        <>
            <div className="container-fluid mt--6">
                <div className="row">
                    <div className="col">
                        <div className="card">
                            {loading &&
                            (
                                <div className="text-center my-auto">
                                    <Loading/>
                                </div>
                            )}
                            {!loading &&
                            (<React.Fragment>
                                    <div className="card-header border-0">
                                        <div className="card-header border-0">
                                            <div className="row align-content-between">
                                                <div className="col-6">
                                                    <h1 className="mb-0">{model.name}</h1>
                                                    <h4>{model.cat}</h4>
                                                </div>
                                                <div className="col-6">
                                                    <h3 className="text-right">
                                                        <span
                                                            className="badge badge-info">{new Date(model.createdAt).toDateString()}
                                                        </span>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h6 className="heading-small text-muted mb-4">Jelly &
                                                        Thumbnails</h6>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="card h-100">
                                                                <img
                                                                    src={`${process.env.REACT_APP_API_URI}/static/vehicles/${model.jelly_img}`}
                                                                    className="card-img-top" alt="Jelly"/>
                                                                <h5 className="card-title pt-3 pl-3">Jelly Image</h5>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="card h-100">
                                                                <img
                                                                    src={`${process.env.REACT_APP_API_URI}/static/vehicles/resized/${model.hero_img}`}
                                                                    className="card-img-top" alt="Hero"/>
                                                                <h5 className="card-title pt-3 pl-3">Hero Image</h5>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="card h-100">
                                                                <img
                                                                    src={`${process.env.REACT_APP_API_URI}/static/vehicles/resized/${model.int_thumb}`}
                                                                    className="card-img-top"
                                                                    alt="Interior Thumb"/>
                                                                <h5 className="card-title pt-3 pl-3">Interior Thumbnail
                                                                    Image</h5>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="card h-100">
                                                                <img
                                                                    src={`${process.env.REACT_APP_API_URI}/static/vehicles/resized/${model.ext_thumb}`}
                                                                    className="card-img-top"
                                                                    alt="Exterior Thumb"/>
                                                                <h5 className="card-title pt-3 pl-3">Exterior Thumbnail
                                                                    Image</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="row">
                                                <div className="col-12">
                                                    <h6 className="heading-small text-muted mb-4">Variants</h6>
                                                    {model.variants.map((variant, i) => {
                                                            const variantFeatures = JSON.parse(variant.features);
                                                            const variantTechFeatures = JSON.parse(variant.techFeatures);

                                                            return (
                                                                <Accordion key={i}>
                                                                    <Card>
                                                                        <Accordion.Toggle as={Card.Header}
                                                                                          eventKey={i + 1}>
                                                                            {variant.name}
                                                                        </Accordion.Toggle>
                                                                        <Accordion.Collapse eventKey={i + 1}>
                                                                            <Card.Body>
                                                                                <table
                                                                                    className={'table table-bordered'}>
                                                                                    <caption>Engine & Powertrain Specs
                                                                                    </caption>
                                                                                    <tbody>
                                                                                    {Object.keys(variantFeatures).map((feature, j) => {
                                                                                        return (
                                                                                            <tr key={j}>
                                                                                                <th className="w-50">{feature}</th>
                                                                                                <td className="w-50">{variantFeatures[feature]}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                    </tbody>
                                                                                </table>
                                                                                <table
                                                                                    className={'table table-bordered'}>
                                                                                    <caption>Creature Comfort Specs
                                                                                    </caption>
                                                                                    <tbody>
                                                                                    {Object.keys(variantTechFeatures).map((feature, j) => {
                                                                                        return (
                                                                                            <tr key={j}>
                                                                                                <th className="w-50">{feature}</th>
                                                                                                <td className="w-50">{variantTechFeatures[feature]}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                    </tbody>
                                                                                </table>
                                                                                <div className="mt-3">
                                                                                    <button
                                                                                        onClick={() => handleShow('variant', variant.id)}
                                                                                        className="btn btn-primary">Delete
                                                                                    </button>
                                                                                </div>
                                                                            </Card.Body>
                                                                        </Accordion.Collapse>
                                                                    </Card>
                                                                </Accordion>
                                                            )
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="d-flex justify-content-between">
                                                        <div><h6 className="heading-small text-muted mb-4">
                                                            Media
                                                        </h6></div>
                                                        <div><span style={{fontSize: '1.1rem'}}
                                                                   className="badge badge-primary">{model.media.length}</span>
                                                            <span className="text-black-50 ml-2">Item/s</span>
                                                        </div>
                                                    </div>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Category (Gallery, Download)</th>
                                                            <th>Picture Type</th>
                                                            <th/>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            model.media.map((m, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{m.name}</td>
                                                                        <td>{
                                                                            m.cat[0].toUpperCase() + m.cat.slice(1)
                                                                        }</td>
                                                                        <td>{
                                                                            m.type === 'ext' ? 'Exterior' : m.type === 'int' ? 'Interior' : 'N/A'
                                                                        }</td>
                                                                        <td>
                                                                            <button
                                                                                onClick={() => handleShow('media', m.id)}
                                                                                className="btn-sm btn-primary">Delete
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-12">
                                                    <Carousel>
                                                        {
                                                            model.media.map((m, i) => {
                                                                if (m.cat === 'gallery') {
                                                                    return (
                                                                        <Carousel.Item key={i}>
                                                                            <img
                                                                                className="d-block w-100"
                                                                                src={`${process.env.REACT_APP_API_URI}/static/vehicles/gallery/${m.file_title}`}
                                                                                alt={m.name}
                                                                            />
                                                                        </Carousel.Item>
                                                                    )
                                                                } else {
                                                                    return null;
                                                                }
                                                            })
                                                        }
                                                    </Carousel>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <h4>Actions</h4>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row mb-3">
                                                        <div className="col-10">
                                                            <p>Edit the model info.</p>
                                                        </div>
                                                        <div className="col">
                                                            <Link to={`edit/${model.id}`}
                                                                  className="btn btn-info w-100">Edit</Link>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-10">
                                                            <p>Add media to the model <small>(Gallery images &
                                                                downloads)</small>.</p></div>
                                                        <div className="col">
                                                            <Link to={`media/add/${model.id}`}
                                                                  className="btn btn-success w-100">Add
                                                                Media</Link>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-10">
                                                            <p>Delete the model permanently.</p>
                                                        </div>
                                                        <div className="col">
                                                            <button className="btn btn-danger w-100"
                                                                    onClick={() => handleShow('model')}>Delete
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Attention required</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure?<br/>
                    <small className="text-danger">(This
                        action can't be undone)</small>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleDelete}>Understood</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};