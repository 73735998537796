import React from "react";
import {Link} from 'react-router-dom';

export default () => {
    return (
        <div className="container-fluid mt--6">
            <div className="row">
                <div className="col">
                    <div className="card min-vh-100">
                        <div className="text-center my-4">
                            <h1 className="error-header">403</h1>
                            <h3 className="text-darker mb-5">Unauthorized</h3>
                            <div className="row justify-content-center">
                                <div className="col">
                                    <Link to="/" className="btn btn-primary">
                                        <i className="fas fa-home"/>
                                        <span>Home</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};