import React from "react";
import {useParams} from 'react-router-dom';
import Loading from "../../Components/Dashboard/Loading";
import {HTTP_METHODS, httpRequest} from "../../services/httpService";
import {bake} from "../../helpers/toaster";

export default (props) => {
    const imageBase = `${process.env.REACT_APP_API_URI}/static/vehicles`;
    const categories = ['SUV', 'Sedan', 'Hatchback', 'Bus', 'Utility'];
    const [isLoading, setLoading] = React.useState(true);
    const [previewJelly, setJellyPreview] = React.useState(null);
    const [previewHero, setHeroPreview] = React.useState(null);
    const [previewExt, setExtPreview] = React.useState(null);
    const [previewInt, setIntPreview] = React.useState(null);
    const [form, setForm] = React.useState({
        name: '',
        cat: '',
        jelly_img: null,
        hero_img: null,
        ext_thumb: null,
        int_thumb: null,
        errors: {
            name: null, cat: null, jelly_img: null, hero_img: null, ext_thumb: null, int_thumb: null,
        }
    });
    const {id} = useParams();
    React.useEffect(() => {
        httpRequest(`${process.env.REACT_APP_API_URI}/vehicles/${id}`, HTTP_METHODS.GET)
            .then(({data}) => {
                setForm(prevState => ({
                    ...prevState,
                    ...data
                }));

                setJellyPreview(`${imageBase}/${data.jelly_img}`);
                setExtPreview(`${imageBase}/resized/${data.ext_thumb}`);
                setIntPreview(`${imageBase}/resized/${data.int_thumb}`);
                setHeroPreview(`${imageBase}/resized/${data.hero_img}`);
            })
            .catch(e => {
                console.error(e);
                const msg = e.response ? e.response.data : e;
                bake(msg, '#ff0000');
                // redirect to 404
                setTimeout(() => {
                    props.history.push('/404?back=models');
                }, 300);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id, imageBase, props.history]);

    const changeListener = (e) => {
        const {name, value, type} = e.target;
        e.persist();

        if (type === 'file') {
            // console.log(name, value, type, e.target.files[0]);
            setForm(prevState => ({
                ...prevState,
                [name]: e.target.files[0]
            }));

            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = (e) => {
                switch (name) {
                    case 'jelly_img':
                        setJellyPreview(reader.result);
                        break;
                    case 'hero_img':
                        setHeroPreview(reader.result);
                        break;
                    case 'int_thumb':
                        setIntPreview(reader.result);
                        break;
                    case 'ext_thumb':
                        setExtPreview(reader.result);
                        break;
                    default:
                        break;
                }
            };
        } else {
            setForm(prevState => (
                {
                    ...prevState,
                    [name]: value
                }
            ));
        }
    };

    const mediaValid = () => {
        let valid = true;
        for (let key in form) {
            const newErrors = form.errors;
            console.log(form[key])
            if (!form[key] && key !== 'errors') {
                newErrors[key] = {
                    msg: 'Invalid input'
                };
                valid = false;
            } else {
                newErrors[key] = null;
            }
            setForm(prevState => ({
                ...prevState,
                errors: newErrors
            }));
        }

        return valid;
    };

    const updateModel = (e) => {
        e.preventDefault();
        // Validate the media
        if (!mediaValid()) {
            return bake('Invalid input', '#ff0000');
        }

        // Build form data
        let formData = new FormData();
        for (let key in form) {
            formData.append(key, form[key]);
        }

        // Set blocker
        setLoading(true);
        // Request
        httpRequest(`${process.env.REACT_APP_API_URI}/vehicles`,
            HTTP_METHODS.PATCH,
            formData, {'Content-Type': 'multipart/form-data'})
            .then((data) => {
                bake('Model updated', '#0275d8');
            })
            .catch(err => {
                if (err.response.status === 422) {
                    const errors = err.response.data.errors;
                    setForm(prevState => (
                        {
                            ...prevState,
                            errors
                        }
                    ));
                    return bake('Invalid input', '#ff0000');
                }

                console.error(err);
                const msg = err.hasOwnProperty('response') ? err.response.data : err;
                bake(msg, '#ff0000');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="container-fluid mt--6">
            <div className="row">
                <div className="col">
                    <div className="card">
                        {isLoading &&
                        (
                            <div className="text-center my-auto">
                                <Loading/>
                            </div>
                        )}
                        {!isLoading && (
                            <>
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col-8">
                                            <h3 className="mb-0">MAS Motors Car Collection</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={updateModel}>
                                        <h6 className="heading-small text-muted mb-4">Car information</h6>
                                        <div className="pl-lg-4">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label"
                                                               htmlFor="input-username">Model Name</label>
                                                        <input type="text" className="form-control" name="name"
                                                               onChange={changeListener}
                                                               placeholder="Camry, Corolla, etc." value={form.name}/>
                                                        {form.errors.name &&
                                                        <small className="text-danger">{form.errors.name.msg}</small>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label"
                                                               htmlFor="input-username">Model Category</label>
                                                        <select name="cat" className="form-control" value={form.cat}
                                                                onChange={changeListener}>
                                                            {categories.map(category => (
                                                                <option value={category}
                                                                        key={category}>{category}</option>))
                                                            }
                                                        </select>
                                                        {form.errors.cat &&
                                                        <small className="text-danger">{form.errors.cat.msg}</small>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label"
                                                               htmlFor="input-username">Jelly Image <small
                                                            className="text-muted">Clear white or background-less
                                                            image</small></label>
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input"
                                                                   name="jelly_img"
                                                                   onChange={changeListener}
                                                                   style={{visibility: 'unset'}}
                                                                   accept=".png, .jpg, .jpeg"/>
                                                            <label className="custom-file-label" htmlFor="customFile">Choose
                                                                file</label>
                                                        </div>
                                                        {form.errors.jelly_img &&
                                                        <small className="text-danger">{form.errors.jelly_img.msg}</small>}
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="col-6 mx-auto mb-3">
                                                        <img src={previewJelly} alt="Preview"
                                                             className="event-preview w-100"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label" htmlFor="input-username">Hero
                                                            Image <small className="text-muted">Image on
                                                                top of page</small>
                                                        </label>
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input"
                                                                   name="hero_img"
                                                                   onChange={changeListener}
                                                                   style={{visibility: 'unset'}}
                                                                   accept=".png, .jpg, .jpeg"/>
                                                            <label className="custom-file-label" htmlFor="customFile">Choose
                                                                file</label>
                                                        </div>
                                                        {form.errors.hero_img &&
                                                        <small className="text-danger">{form.errors.hero_img.msg}</small>}
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="col-6 mx-auto mb-3">
                                                        <img src={previewHero} alt="Preview"
                                                             className="event-preview w-100"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label" htmlFor="input-username">Gallery
                                                            Exterior Thumbnail
                                                        </label>
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input"
                                                                   name="ext_thumb"
                                                                   onChange={changeListener}
                                                                   style={{visibility: 'unset'}}
                                                                   accept=".png, .jpg, .jpeg"/>
                                                            <label className="custom-file-label" htmlFor="customFile">Choose
                                                                file</label>
                                                        </div>
                                                        {form.errors.ext_thumb &&
                                                        <small
                                                            className="text-danger">{form.errors.ext_thumb.msg}</small>}
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    {form.ext_thumb &&
                                                    <div className="col-6 mx-auto mb-3">
                                                        <img src={previewExt} alt="Preview"
                                                             className="event-preview w-100"/>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-control-label" htmlFor="input-username">Gallery
                                                            Interior Thumbnail</label>
                                                        <div className="custom-file">
                                                            <input type="file" className="custom-file-input"
                                                                   name="int_thumb"
                                                                   onChange={changeListener}
                                                                   style={{visibility: 'unset'}}
                                                                   accept=".png, .jpg, .jpeg"/>
                                                            <label className="custom-file-label" htmlFor="customFile">Choose
                                                                file</label>
                                                        </div>
                                                        {form.errors.int_thumb &&
                                                        <small
                                                            className="text-danger">{form.errors.int_thumb.msg}</small>}
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    {form.int_thumb &&
                                                    <div className="col-6 mx-auto mb-3">
                                                        <img src={previewInt} alt="Preview"
                                                             className="event-preview w-100"/>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                            <button className="btn btn-primary">
                                                Update
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}