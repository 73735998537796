import React from "react";
import {useLocation} from 'react-router-dom';
import {routes} from "../../routes";

export default (props) => {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const back = urlParams.get('back');
    let url;

    if (back) {
        [url] = routes.filter((route) => route.title.toLowerCase() === back);
    }

    url = url ? url.path : '/';

    const goBack = () => {
        props.history.push(url);
    };

    const goHome = () => {
        props.history.push('/');
    };

    return (
        <div className="container-fluid mt--6">
            <div className="row">
                <div className="col">
                    <div className="card min-vh-100">
                        <div className="text-center my-4">
                            <h1 className="error-header">404</h1>
                            <h3 className="text-darker mb-5">Resource not found</h3>
                            <div className="row justify-content-center">
                                <div className="col-2">
                                    <button onClick={goBack} className="btn btn-danger">
                                        <i className="fas fa-arrow-left"/>
                                        <span>Go Back</span>
                                    </button>
                                </div>
                                <div className="col-2">
                                    <button onClick={goHome} className="btn btn-primary">
                                        <i className="fas fa-home"/>
                                        <span>Home</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};