import {combineReducers} from "redux";

const dom = (state = {sideNavBar: false}, action) => {
    if (action.type === 'TOGGLE_NAV') {
        return {
            ...state,
            sideNavBar: !state.sideNavBar
        };
    } else {
        return state
    }
};

export default combineReducers({dom});