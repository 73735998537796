import React from "react";
import {httpRequest, HTTP_METHODS} from "../../services/httpService";
import {bake} from "../../helpers/toaster";
import UiBlock from "../../Components/Dashboard/UiBlock";

const EventCreate = (props) => {
    const [form, setForm] = React.useState({
        title: '',
        title_ar: '',
        type: 'News',
        desc: '',
        desc_ar: '',
        image: null,
        errors: {title: null, desc: null, type: null}
    });
    const [isLoading, setLoading] = React.useState(false);
    const [preview, setPreview] = React.useState(null);

    const changeListener = (e) => {
        e.persist(); // Prevent app from crushing because the value needs to be nullified
        const {name, value, type} = e.target;
        if (type === 'file') {
            // console.log(name, value, type, e.target.files[0]);
            setForm(prevState => ({
                ...prevState,
                image: e.target.files[0]
            }));
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = function (e) {
                setPreview(reader.result)
            };
        } else {
            setForm(prevState => ({
                    ...prevState,
                    [name]: value
                })
            );
        }
    };

    const createEvent = (e) => {
        e.preventDefault();
        // Build form data
        let formData = new FormData();
        for (let key in form) {
            formData.append(key, form[key]);
        }

        // Set blocker
        setLoading(true);
        // Request
        httpRequest(`${process.env.REACT_APP_API_URI}/events`,
            HTTP_METHODS.POST,
            formData, {'Content-Type': 'multipart/form-data'})
            .then((data) => {
                // Show success message for the user
                bake('Event created', '#0275d8');
                // Clear fields
                setForm({
                    title: '',
                    title_ar: '',
                    desc: '',
                    desc_ar: '',
                    image: null,
                    errors: {title: null, title_at:null, desc: null, desc_ar: null}
                });
            })
            .catch(err => {
                if (err.response.status === 422) {
                    const errors = err.response.data.errors;
                    setForm(prevState => (
                        {
                            ...prevState,
                            errors
                        }
                    ));
                    return bake('Invalid input', '#ff0000');
                }

                console.error(err);
                const msg = err.hasOwnProperty('response') ? err.response.data : err;
                bake(msg, '#ff0000');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="container-fluid mt--6">
            {isLoading && <UiBlock/>}
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <h3 className="mb-0">MAS Motors LLC. Event</h3>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={createEvent}>
                                <h6 className="heading-small text-muted mb-4">Event information</h6>
                                <div className="pl-lg-4">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-username">Title</label>
                                                <input type="text" className="form-control" name="title"
                                                       onChange={changeListener}
                                                       placeholder="Summer Promo for RAV4" value={form.title}/>
                                                {form.errors.title &&
                                                <small className="text-danger">{form.errors.title.msg}</small>}
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-username">Title Arabic</label>
                                                <input type="text" className="form-control" name="title_ar"
                                                       onChange={changeListener}
                                                       placeholder="حملة صيف 2021" value={form.title_ar}/>
                                                {form.errors.title_ar &&
                                                <small className="text-danger">{form.errors.title_ar.msg}</small>}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-username">Type</label>
                                                <div className="custom-control custom-radio mb-3">
                                                    <input type="radio" id="promoRadio" name="type"
                                                           value="Promo" onChange={changeListener}
                                                           className="custom-control-input"
                                                           checked={form.type === 'Promo'}/>

                                                    <label className="custom-control-label"
                                                           htmlFor="promoRadio">Promotion</label>
                                                </div>
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" id="newsRadio" name="type"
                                                           value="News" onChange={changeListener}
                                                           className="custom-control-input"
                                                           checked={form.type === 'News'}/>

                                                    <label className="custom-control-label"
                                                           htmlFor="newsRadio">News</label>
                                                </div>
                                                {form.errors.type &&
                                                <small className="text-danger">{form.errors.type.msg}</small>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-username">Description</label>
                                                <textarea value={form.desc} name="desc" className="form-control"
                                                          onChange={changeListener}
                                                          style={{height: '150px', resize: 'none'}}/>
                                                {form.errors.desc &&
                                                <small className="text-danger">{form.errors.desc.msg}</small>}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-username">Description Arabic</label>
                                                <textarea value={form.desc_ar} name="desc_ar" className="form-control"
                                                          onChange={changeListener}
                                                          style={{height: '150px', resize: 'none'}}/>
                                                {form.errors.desc_ar &&
                                                <small className="text-danger">{form.errors.desc_ar.msg}</small>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {form.image &&
                                        <div className="col-6 mx-auto mb-3">
                                            <img src={preview} alt="Preview" className="event-preview"/>
                                        </div>
                                        }
                                        <div className="col-12">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" name="image"
                                                       onChange={changeListener}
                                                       style={{visibility: 'unset'}} accept=".png, .jpg, .jpeg"/>
                                                <label className="custom-file-label" htmlFor="customFile">Choose
                                                    file</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mt-5">
                                            <button className="btn btn-primary"> Create</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default EventCreate;
