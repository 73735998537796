import React from 'react';
import Loading from "../../Components/Dashboard/Loading";
import {useParams} from 'react-router-dom';
import {HTTP_METHODS, httpRequest} from "../../services/httpService";
import {bake} from "../../helpers/toaster";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

const Event = (props) => {
    const [event, setEvent] = React.useState({
        title: '',
        type: '',
        desc: '',
        createdAt: null,
        image_title: '',
        image_ext: ''
    });
    const [loading, setLoading] = React.useState(true);
    const [show, setShow] = React.useState(false);
    const {id} = useParams();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = () => {
        // Send HTTP request to sever
        httpRequest(`${process.env.REACT_APP_API_URI}/events`, HTTP_METHODS.DELETE, {id})
            .then(({data}) => {
                // If successful navigate to events
                setShow(false);
                setTimeout(() => {
                    props.history.push('/events');
                }, 500);
            })
            .catch(e => {
                console.error(e);
                let msg;
                if (e.response.status === 422) {
                    msg = 'Invalid request';
                } else {
                    msg = e.response ? e.response.data : e;
                }
                bake(msg, '#ff0000');
            });
    };


    React.useEffect(() => {
        httpRequest(`${process.env.REACT_APP_API_URI}/events/${id}`, HTTP_METHODS.GET)
            .then(({data}) => {
                setEvent(data);
            })
            .catch(e => {
                const msg = e.response ? e.response.data : e;
                if (e.response && e.response.status === 404) {
                    // redirect to 404
                    setTimeout(() => {
                        props.history.push('/404?back=events');
                    }, 300);
                } else if (e === 'Network Error') {
                    setTimeout(() => {
                        props.history.push('/errors/network?back=events');
                    }, 300);
                }
                bake(msg, '#ff0000');
            }).finally(() => {
            setLoading(false);
        });
    }, [id, props.history]);

    return (
        <>
            <div className="container-fluid mt--6">
                <div className="row">
                    <div className="col">
                        <div className="card">
                            {loading &&
                            (
                                <div className="text-center my-auto">
                                    <Loading/>
                                </div>
                            )}
                            {!loading &&
                            (<React.Fragment>
                                    <div className="card-header border-0">
                                        <div className="card-header border-0">
                                            <div className="row align-content-between">
                                                <div className="col-6">
                                                    <h3 className="mb-0">{event.title}</h3>
                                                    <h3 className="mb-0">{event.title_ar}</h3>
                                                    <h2><span
                                                        className={`badge ${event.type === 'Promo' ? 'badge-primary' : 'badge-danger'}`}>{event.type}</span>
                                                    </h2>
                                                </div>
                                                <div className="col-6">
                                                    <h3 className="text-right">
                                        <span className="badge badge-info">
                                            {new Date(event.createdAt).toDateString()}
                                        </span>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <p>
                                                        {event.desc}
                                                    </p>
                                                    <p>
                                                        {event.desc_ar}
                                                    </p>
                                                </div>
                                                <div className="col-6">
                                                    <img
                                                        className="img-fluid img-thumbnail"
                                                        src={`${process.env.REACT_APP_API_URI}/static/events/resized/${event.image_title}`}
                                                        alt="preview"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <h4>Actions</h4>
                                            <div className="row">
                                                <div className="col">
                                                    <p>Edit the event info.</p>
                                                    <Link to={`edit/${event.id}`} className="btn btn-info">Edit</Link>
                                                </div>
                                                <div className="col">
                                                    <p>Delete the event permanently.</p>
                                                    <button className="btn btn-danger" onClick={handleShow}>Delete
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Attention required</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure?<br/>
                    <small className="text-danger">(This
                        action can't be undone)</small>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleDelete}>Understood</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Event;
