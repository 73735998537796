import jwt_decode from 'jwt-decode';

export function data(token) {
    let decoded;
    try {
        decoded = jwt_decode(token);
    } catch (e) {
        console.error(e);
        return e;
    }
    return decoded.data;
};

export function isAdmin(token) {
    try {
        const decoded = data(token);
        return decoded.isAdmin;
    } catch (e) {
        console.error(e);
        return false;
    }
}