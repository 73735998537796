import React from "react";

const UiBlock = () => (
    <div className="ui-block">
        <div className="spinner-grow text-danger" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <div>
            <h6 className="display-5 text-black-50 text-uppercase">Loading</h6>
        </div>
    </div>
);

export default UiBlock;