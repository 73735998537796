import React from "react";
import {httpRequest} from "../../services/httpService";
import {Link} from "react-router-dom";
import Loading from "../../Components/Dashboard/Loading";
import Table from "../../Components/Dashboard/Table";

export default () => {
    const [models, setModels] = React.useState([]);
    const [isLoading, setLoading] = React.useState(true);

    React.useEffect(() => {
        httpRequest(`${process.env.REACT_APP_API_URI}/vehicles`)
            .then(({data}) => {
                setModels(data);
            })
            .catch(e => {
                console.error(e);
            })
            .finally(() => setLoading(false));
    }, []);

    const columns = React.useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Category',
            accessor: 'cat',
        },
        {
            Header: 'Date',
            accessor: 'createdAt',
        },
        {
            Header: 'View',
            accessor: (event => (
                <Link to={`/models/${event.id}`}
                      className={"btn-sm btn-primary"}>
                    View
                </Link>
            )),
        },
    ], []);

    return (
        <div className="container-fluid mt--6">
            <div className="row">
                <div className="col">
                    <div className="card">
                        {
                            isLoading && (
                                <div className="text-center my-auto">
                                    <Loading/>
                                </div>
                            )
                        }
                        {
                            !isLoading && (
                                <React.Fragment>
                                    <div className="card-header border-0">
                                        <h3 className="mb-0">MAS Motors Models</h3>
                                    </div>
                                    <Table columns={columns} data={models}/>
                                </React.Fragment>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}