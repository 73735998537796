import React from "react";
import {useParams} from 'react-router-dom';
import {HTTP_METHODS, httpRequest} from "../../services/httpService";
import {bake} from "../../helpers/toaster";
import Loading from "../../Components/Dashboard/Loading";

export default (props) => {
    const {id} = useParams();
    const [event, setEvent] = React.useState({
        title: '',
        title_ar: '',
        type: '',
        desc: '',
        desc_ar: '',
        createdAt: null,
        image_title: '',
        image_ext: '',
        errors: {title: null, desc: null, type: null}
    });
    const [loading, setLoading] = React.useState(true);
    const [preview, setPreview] = React.useState(null);

    React.useEffect(() => {
        httpRequest(`${process.env.REACT_APP_API_URI}/events/${id}`, HTTP_METHODS.GET)
            .then(({data}) => {
                setEvent(prevState => ({
                    ...prevState,
                    ...data
                }));
                const imgSrc = `${process.env.REACT_APP_API_URI}/static/events/resized/${data.image_title}`;
                setPreview(imgSrc);
            })
            .catch(e => {
                console.error(e);
                const msg = e.response ? e.response.data : e;
                bake(msg, '#ff0000');
                // redirect to 404
                setTimeout(() => {
                    props.history.push('/404?back=events');
                }, 300);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id, props.history]);

    const changeListener = (e) => {
        e.persist();
        // Prevent app from crushing because the value needs to be nullified
        const {name, value, type} = e.target;
        if (type === 'file') {
            setEvent(prevState => ({
                ...prevState,
                image: e.target.files[0]
            }));

            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = function (e) {
                setPreview(reader.result)
            };

        } else {
            setEvent(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const updateEvent = (e) => {
        e.preventDefault();
        // Build form data
        let formData = new FormData();
        for (let key in event) {
            formData.append(key, event[key]);
        }

        setLoading(true);

        httpRequest(`${process.env.REACT_APP_API_URI}/events`, HTTP_METHODS.PATCH, formData,
            {'Content-Type': 'multipart/form-data'}
        )
            .then((data) => {
                bake('Event updated', '#0275d8');
                // Clear fields
                setEvent({
                    title: '',
                    title_ar: '',
                    desc: '',
                    desc_ar: '',
                    image: null,
                    errors: {title: null, desc: null}
                });

                props.history.push(`/events/${id}`);
            })
            .catch(err => {
                if (err.response.status === 422) {
                    const errors = err.response.data.errors;
                    setEvent(prevState => (
                        {
                            ...prevState,
                            errors
                        }
                    ));
                    return bake('Invalid input', '#ff0000');
                }
                console.error(err);
                const msg = err.hasOwnProperty('response') ? err.response.data : err;
                bake(msg, '#ff0000');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <>
            <div className="container-fluid mt--6">
                <div className="row">
                    <div className="col">
                        <div className="card">
                            {loading &&
                            (
                                <div className="text-center my-auto">
                                    <Loading/>
                                </div>
                            )}
                            {!loading &&
                            (<React.Fragment>
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col-8">
                                            <h3 className="mb-0">MAS Motors LLC. Event</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={updateEvent}>
                                        <h6 className="heading-small text-muted mb-4">Event information</h6>
                                        <div className="pl-lg-4">
                                            <div className="row">
                                                <div className="col-lg-8">
                                                    <div className="form-group">
                                                        <label className="form-control-label"
                                                               htmlFor="input-username">Title</label>
                                                        <input type="text" className="form-control" name="title"
                                                               onChange={changeListener}
                                                               placeholder="Summer Promo for RAV4" value={event.title}/>
                                                        {event.errors.title &&
                                                        <small className="text-danger">{event.errors.title.msg}</small>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="form-control-label"
                                                               htmlFor="input-username">Title Arabic</label>
                                                        <input type="text" className="form-control" name="title_ar"
                                                               onChange={changeListener}
                                                               placeholder="Summer Promo for RAV4" value={event.title_ar}/>
                                                        {event.errors.title_ar &&
                                                        <small className="text-danger">{event.errors.title_ar.msg}</small>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="form-group">
                                                        <label className="form-control-label"
                                                               htmlFor="input-username">Type</label>
                                                        <div className="custom-control custom-radio mb-3">
                                                            <input type="radio" id="promoRadio" name="type"
                                                                   value="Promo" onChange={changeListener}
                                                                   className="custom-control-input"
                                                                   checked={event.type === 'Promo'}/>

                                                            <label className="custom-control-label"
                                                                   htmlFor="promoRadio">Promotion</label>
                                                        </div>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" id="newsRadio" name="type"
                                                                   value="News" onChange={changeListener}
                                                                   className="custom-control-input"
                                                                   checked={event.type === 'News'}/>

                                                            <label className="custom-control-label"
                                                                   htmlFor="newsRadio">News</label>
                                                        </div>
                                                        {event.errors.type &&
                                                        <small className="text-danger">{event.errors.type.msg}</small>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label className="form-control-label"
                                                               htmlFor="input-username">Description</label>
                                                        <textarea value={event.desc} name="desc"
                                                                  className="form-control"
                                                                  onChange={changeListener}
                                                                  style={{height: '150px', resize: 'none'}}/>
                                                        {event.errors.desc &&
                                                        <small className="text-danger">{event.errors.desc.msg}</small>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="form-control-label"
                                                               htmlFor="input-username">Description Arabic</label>
                                                        <textarea value={event.desc_ar} name="desc_ar"
                                                                  className="form-control"
                                                                  onChange={changeListener}
                                                                  style={{height: '150px', resize: 'none'}}/>
                                                        {event.errors.desc_ar &&
                                                        <small className="text-danger">{event.errors.desc_ar.msg}</small>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 mx-auto mb-3">
                                                    <img src={preview} alt="Preview" className="event-preview" width={300}/>
                                                </div>
                                                <div className="col-12">
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" name="image"
                                                               onChange={changeListener}
                                                               style={{visibility: 'unset'}}
                                                               accept=".png, .jpg, .jpeg"/>
                                                        <label className="custom-file-label" htmlFor="customFile">Choose
                                                            file</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mt-5">
                                                    <button className="btn btn-primary">Update</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </React.Fragment>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
