// React
import React from 'react';
import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import {AnimatedSwitch} from "react-router-transition";
import {Provider} from "react-redux";
import {createStore} from 'redux'
import rootReducer from './store/reducers';

// Assets
import './assets/styles/App.scss';
import './assets/js/bootstrap';
import "toastify-js/src/toastify.css";

// Routes
import {routes} from './routes';

// Redux store
const store = createStore(rootReducer);

const AppRoute = ({component: Component, layout: Layout, title, ...rest}) => (
    <Route {...rest} render={props => (
        <Layout title={title}>
            <Component {...props} />
        </Layout>
    )}/>
);

const PrivateRoute = ({component: Component, layout: Layout, title, ...rest}) => (
    <Route {...rest} render={props =>
        localStorage.getItem('token') ? (
            <Layout title={title} pathname={props.location.pathname}>
                <Component {...props}/>
            </Layout>
        ) : <Redirect to={{
            pathname: "/login",
            state: {from: props.location}
        }}/>
    }/>
);

function App() {
    return (
        <Provider store={store}>
            <Router>
                <div className="App">
                    <AnimatedSwitch
                        atEnter={{opacity: 0}}
                        atLeave={{opacity: 0}}
                        atActive={{opacity: 1}}
                        className="switch-wrapper"
                    >
                        {
                            routes.map((page, id) => (
                                page.private ?
                                    <PrivateRoute exact path={page.path} component={page.component} layout={page.layout}
                                                  key={id} title={page.title}/>
                                    :
                                    <AppRoute exact path={page.path} component={page.component} layout={page.layout}
                                              key={id} title={page.title}/>
                            ))
                        }
                    </AnimatedSwitch>
                </div>
            </Router>
        </Provider>
    );
}

export default App;
