import React from "react";
import {Link, useParams} from 'react-router-dom';
import Loading from "../../Components/Dashboard/Loading";
import {HTTP_METHODS, httpRequest} from "../../services/httpService";
import {bake} from "../../helpers/toaster";
import {isAdmin} from "../../helpers/jwt";
import Error403 from "../Errors/Error403";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";


export default (props) => {
    const deleteBody = `Are you sure?<br/><small class="text-danger">(This action can't be undone)</small>`;
    const statusBody = `Are you sure?`;

    const {id} = useParams();
    const [loading, setLoading] = React.useState(true);
    const [show, setShow] = React.useState(false);
    const [user, setUser] = React.useState({});
    const [modalInfo, setModalInfo] = React.useState({src: null, body: null});

    const admin = isAdmin(localStorage.getItem('token'));

    const handleClose = () => setShow(false);
    const handleShow = (src) => {
        if (src === 'del') {
            setModalInfo({
                src: 'del',
                body: deleteBody
            });
        } else if (src === 'status') {
            setModalInfo({
                src: 'status',
                body: statusBody
            });
        }
        setShow(true);
    };

    React.useEffect(() => {
        if (admin) {
            httpRequest(`${process.env.REACT_APP_API_URI}/users/${id}`, HTTP_METHODS.GET)
                .then(({data}) => {
                    setUser(data);
                })
                .catch(e => {
                    const msg = e.response ? e.response.data : e;
                    if (e.response && e.response.status === 404) {
                        // redirect to 404
                        setTimeout(() => {
                            props.history.push('/404?back=users');
                        }, 300);
                    } else if (e === 'Network Error') {
                        setTimeout(() => {
                            props.history.push('/errors/network?back=users');
                        }, 300);
                    }

                    bake(msg, '#ff0000');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [id, admin, props.history]);

    const handleModalAction = () => {
        switch (modalInfo.src) {
            case 'del':
                httpRequest(`${process.env.REACT_APP_API_URI}/users`, HTTP_METHODS.DELETE, {id})
                    .then(({data}) => {
                        // If successful navigate to events
                        setShow(false);
                        setTimeout(() => {
                            props.history.push('/users');
                        }, 500);
                    })
                    .catch(e => {
                        console.error(e);
                        let msg;
                        if (e.response.status === 422) {
                            msg = 'Invalid request';
                        } else {
                            msg = e.response ? e.response.data : e;
                        }
                        bake(msg, '#ff0000');
                    });
                break;
            case 'status':
                const status = user.status === 'Active' ? 'Disabled' : 'Active';
                const data = {
                    id,
                    status
                };

                httpRequest(`${process.env.REACT_APP_API_URI}/users/status`, HTTP_METHODS.PATCH, data)
                    .then(({data}) => {
                        // If successful navigate to events
                        setShow(false);
                        setTimeout(() => {
                            props.history.push('/users');
                        }, 500);
                    }).catch(e => {
                    console.error(e)
                });
                break;
            default:
                break;
        }
    };


    // Redirect to 403 on unauthorized user
    if (!admin) {
        return <Error403/>
    }

    return (
        <>
            <div className="container-fluid mt--6">
                <div className="row">
                    <div className="col">
                        <div className="card">
                            {loading &&
                            (
                                <div className="text-center my-auto">
                                    <Loading/>
                                </div>
                            )}
                            {
                                !loading &&
                                (<React.Fragment>
                                    <div className="card-header border-0">
                                        <div className="card-header border-0">
                                            <div className="row align-content-between">
                                                <div className="col-6">
                                                    <h2 className="mb-0">{user.firstName} {user.lastName}</h2>
                                                    <h3>{user.title}</h3>
                                                </div>
                                                <div className="col-6">
                                                    <h3 className="text-right"><span
                                                        className="badge badge-info">{new Date(user.createdAt).toDateString()}</span>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h4>Email</h4>
                                                </div>
                                                <div className="col">
                                                    <p>{user.email}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <h4>Status</h4>
                                                </div>
                                                <div className="col">
                                                    <h4><span
                                                        className={`badge ${user.status === 'Active' ? 'badge-success' : 'badge-danger'}`}>{user.status}</span>
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <h4>Role</h4>
                                                </div>
                                                <div className="col">
                                                    <h4><span
                                                        className={`badge ${user.isAdmin ? 'badge-primary' : 'badge-info'}`}>{user.isAdmin ? 'Admin' : 'User'}</span>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>)
                            }
                            {
                                !loading &&
                                <div className="card-footer">
                                    <h4>Actions</h4>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row mb-4">
                                                <div className="col-10">
                                                    <h5 className="text-muted">Update Info</h5>
                                                    <span className="text-gray-dark">Update the user info like name, password, etc.</span>
                                                </div>
                                                <div className="col-2">
                                                    <div className="mt-3">
                                                        <Link to={`edit/${user.id}`}
                                                              className="btn btn-info w-100">Edit</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-10">
                                                    <h5 className="text-muted">{user.status === 'Active' ? 'Disable' : 'Activate'} Account</h5>
                                                    <span
                                                        className="text-gray-dark">{user.status === 'Active' ? 'Disable account from accessing the system'
                                                        : 'Enable account to access the system'}.</span>
                                                </div>
                                                <div className="col-2">
                                                    <div className="mt-3">
                                                        <button className="btn btn-warning w-100"
                                                                onClick={() => handleShow('status')}>
                                                            {user.status === 'Active' ? 'Deactivate' : 'Activate'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-10">
                                                    <h5 className="text-muted">Delete User</h5>
                                                    <span className="text-gray-dark">Delete the user permanently from the system.</span>
                                                </div>
                                                <div className="col-2">
                                                    <div className="mt-3 ml-auto">
                                                        <button className="btn btn-danger w-100"
                                                                onClick={() => handleShow('del')}>Delete
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Attention required</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{__html: modalInfo.body}}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleModalAction}>Understood</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};