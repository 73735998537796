import React from "react";
import {useTable, usePagination} from 'react-table'

const Table = ({columns, data}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
            initialState: {pageIndex: 0},
        },
        usePagination
    );

    return (
        <React.Fragment>
            <table {...getTableProps()} className="table align-items-center table-flush">
                <thead className="thead-light">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.toString()}>
                        <th className="sort">No.</th>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()} className="sort">
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()} className="list">
                {page.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} key={i}>
                            <td>{i + 1}</td>
                            {row.cells.map(cell => {
                                const today = new Date();
                                const bookingDate = new Date(cell.value);

                                return (
                                    <td {...cell.getCellProps()}>
                                        {(() => {
                                            if (cell.column.Header === 'Date') {
                                                return <span
                                                    className={`badge badge-lg ${today.getTime() > bookingDate.getTime() ? 'badge-danger' : 'badge-default'}`}>
                                                        {bookingDate.toDateString()}
                                            </span>
                                            }
                                            else {
                                                return cell.render('Cell')
                                            }
                                        })()
                                        }
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
            <div className="card-footer py-4">
                <div className="d-flex justify-content-between">
                    <div>
                        <span> Page {pageIndex + 1} of {pageOptions.length}</span>
                        <select className="form-control"
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                    <nav>
                        <ul className="pagination justify-content-end mb-0">
                            <li className="page-item">
                                <button className="page-link" tabIndex="-1" onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}>
                                    <i className="fas fa-angle-double-left"/>
                                    <span className="sr-only">Previous</span>
                                </button>
                            </li>
                            <li className="page-item">
                                <button className="page-link" tabIndex="-1" onClick={() => previousPage()}
                                        disabled={!canPreviousPage}>
                                    <i className="fas fa-angle-left"/>
                                    <span className="sr-only">Previous</span>
                                </button>
                            </li>
                            {
                                pageOptions.map(option => {
                                    return (
                                        <li className={`page-item ${option === pageIndex ? 'active' : ''}`}
                                            key={option.toString()}>
                                            <button className="page-link"
                                                    onClick={() => gotoPage(option)}>{option + 1}</button>
                                        </li>
                                    )
                                })
                            }
                            <li className="page-item">
                                <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                                    <i className="fas fa-angle-right"/>
                                    <span className="sr-only">Next</span>
                                </button>
                            </li>
                            <li className="page-item">
                                <button className="page-link" onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}>
                                    <i className="fas fa-angle-double-right"/>
                                    <span className="sr-only">Next</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    )
};

export default Table;