import React from 'react';

const Loading = (props) => (
    <React.Fragment>
        <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <h3>Please wait</h3>
    </React.Fragment>
);

export default Loading;