import React from "react";
import {useLocation, Link} from 'react-router-dom';

const Header = (props) => {
    const {title} = props;
    let location = useLocation();
    const [crumbs, setCrumbs] = React.useState([]);

    React.useEffect(() => {
        if (location.pathname.length > 1) {
            const crumbs = location.pathname.split('/');
            setCrumbs(crumbs);
        }
    }, [location.pathname]);

    return (
        <div className="header bg-primary pb-6">
            <div className="container-fluid">
                <div className="header-body">
                    <div className="row align-items-center py-4">
                        <div className="col-lg-6 col-7">
                            <h6 className="h2 text-white d-inline-block mb-0">{title}</h6>
                            <nav aria-label="breadcrumb" className="d-none d-md-inline-block ml-md-4">
                                <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
                                    <li className="breadcrumb-item">
                                        <Link to={"/"}>
                                            <i className="fas fa-home"/>
                                        </Link>
                                    </li>
                                    {
                                        crumbs.map((crumb, id) => {
                                            if (crumb) {
                                                if (isNaN(crumb) && !['variants', 'create', 'update', 'media', 'clients', 'messages', 'bookings', 'edit', 'add'].includes(crumb)) {
                                                    return (
                                                        <li className="breadcrumb-item" key={id}>
                                                            <Link to={'/' + crumb}>{crumb.toUpperCase()}</Link>
                                                        </li>);
                                                } else {
                                                    return (
                                                        <li className="breadcrumb-item" key={id}>
                                                            {crumb.toUpperCase()}
                                                        </li>);
                                                }
                                            }
                                            // crumb && (
                                            //     <li className="breadcrumb-item" key={id}>
                                            //         <Link to={'/' + crumb}>{crumb.toUpperCase()}</Link>
                                            //     </li>
                                            // )

                                        })
                                    }
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;