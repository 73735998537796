import React from "react";
import {NavLink} from "react-router-dom";
import Logo from '../../assets/images/logo-simple.png';
import {data} from '../../helpers/jwt';
import {connect} from 'react-redux';
import {toggleSideNav} from "../../store/actions";

const SideNavBar = ({isActive, dispatch}) => {
    const scrollBarStyle = {
        height: '957px', marginBottom: '0px', marginRight: '0px', maxHeight: 'none'
    };

    const links = [
        {
            title: 'Dashboard',
            url: '/',
            icon: 'fas fa-chart-line text-primary',
            children: []
        }, {
            title: 'Read Messages',
            url: '/clients/messages',
            icon: 'far fa-envelope text-warning',
            children: []
        }, {
            title: 'Service Bookings',
            url: '/clients/bookings',
            icon: 'far fa-calendar text-dark',
            children: []
        }, {
            title: 'Events',
            url: '/events/',
            icon: 'fas fa-air-freshener',
            children: []
        }, {
            title: 'New Event',
            url: '/events/create',
            icon: 'far fa-newspaper',
            children: []
        }, {
            title: 'Users',
            url: '/users/',
            icon: 'fas fa-user-circle',
            children: []
        }, {
            title: 'Car Collection',
            url: '',
            icon: 'fas fa-car',
            children: [
                {
                    title: 'View Models',
                    url: '/models/',
                },
                {
                    title: 'Create Model',
                    url: '/models/create',
                },
                {
                    title: 'Create Variant (SFX)',
                    url: "/models/variants/create"
                },
            ]
        }
    ];

    let decoded;
    try {
        decoded = data(localStorage.getItem('token'));
    } catch (e) {
        console.log(e)
    }
    const {isAdmin, title, email} = decoded;
    const closeNav = () => {
        if (isActive) {
            dispatch(toggleSideNav());
        }
    };

    const [childActive, setChildStatus] = React.useState(false);
    const toggleActive = () => setChildStatus(!childActive);

    return (
        <nav
            className={`sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white ${isActive ? 'active' : null}`}
            id="sidenav-main">
            <div className="scroll-wrapper scrollbar-inner" style={{position: 'relative'}}>
                <div className="scrollbar-inner scroll-content"
                     style={scrollBarStyle}>
                    {/*  <!-- Brand --> */}
                    <div className="sidenav-header  align-items-center">
                        <a className="navbar-brand" href="/" rel="noopener noreferrer">
                            <img src={Logo} className="navbar-brand-img" alt="logo"/>
                        </a>
                    </div>
                    <div className="text-center align-items-center">
                        <span style={{fontSize: 12}}>{email}</span><br/>
                        <b>{title}</b>
                    </div>
                    <div className="navbar-inner">
                        {/*    <!-- Collapse -->*/}
                        <div className="collapse navbar-collapse" id="sidenav-collapse-main">
                            {/*     <!-- Nav items -->*/}
                            <ul className="navbar-nav">
                                {
                                    /* eslint-disable array-callback-return */
                                    links.map(link => {
                                            if (link.url.includes('users') && isAdmin) {
                                                return (<li className="nav-item" key={link.title}>
                                                    <NavLink exact={true} onClick={closeNav} activeClassName={'active'}
                                                             className="nav-link"
                                                             to={link.url}>
                                                        <i className={link.icon}/>
                                                        <span className="nav-link-text">{link.title}</span>
                                                    </NavLink>
                                                </li>)
                                            } else if (!link.url.includes('users')) {
                                                if (link.children.length) {
                                                    return (
                                                        <li className={`nav-item nav-with-child ${childActive ? 'nav-item-expanded' : ''}`}
                                                            onClick={toggleActive} key={link.title}>
                                                            <a className="nav-link" href="#!">
                                                                <i className={link.icon}/>
                                                                <span className="nav-link-text">{link.title}</span>
                                                            </a>
                                                            <ul className="nav-item-child">
                                                                {
                                                                    link.children.map((child, i) => (
                                                                        <li className="nav-item" key={i}>
                                                                            <NavLink className="nav-link" to={child.url}
                                                                                     activeClassName={'active'}
                                                                                     exact={true}>
                                                                                {child.title}
                                                                            </NavLink>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </li>
                                                    )
                                                } else {
                                                    return (
                                                        <li className="nav-item" key={link.title}>
                                                            <NavLink exact={true} onClick={closeNav}
                                                                     activeClassName={'active'}
                                                                     className="nav-link"
                                                                     to={link.url}>
                                                                <i className={link.icon}/>
                                                                <span className="nav-link-text">{link.title}</span>
                                                            </NavLink>
                                                        </li>
                                                    )
                                                }
                                            }
                                        }
                                    )
                                }
                            </ul>
                            {/*     <!-- Divider -->*/}
                            <hr className="my-3"/>
                            {/*   <!-- Navigation -->*/}
                        </div>
                    </div>
                </div>
                <div className="scroll-element scroll-x">
                    <div className="scroll-element_outer">
                        <div className="scroll-element_size"/>
                        <div className="scroll-element_track"/>
                        <div className="scroll-bar" style={{width: '0px'}}/>
                    </div>
                </div>
                <div className="scroll-element scroll-y">
                    <div className="scroll-element_outer">
                        <div className="scroll-element_size"/>
                        <div className="scroll-element_track"/>
                        <div className="scroll-bar" style={{height: '0px'}}></div>
                    </div>
                </div>
            </div>
        </nav>);
};

const mapStateToProps = (state) => ({
    isActive: state.dom.sideNavBar
});

export default connect(mapStateToProps, null)(SideNavBar);