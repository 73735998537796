import React from "react";
import {HTTP_METHODS, httpRequest} from "../../../services/httpService";
import {bake} from "../../../helpers/toaster";
import UiBlock from "../../../Components/Dashboard/UiBlock";

export default (props) => {
    const [isLoading, setLoading] = React.useState(false);
    const [models, setModels] = React.useState([]);
    const [form, setForm] = React.useState({
        name: '',
        id: null,
        features: {},
        techFeatures: {},
        errors: {name: null, features: null, techFeatures: null, id: null}
    });

    const [feature, setFeature] = React.useState({title: '', desc: ''});
    const [techFeature, setTechFeature] = React.useState({title: '', desc: ''});

    React.useEffect(() => {
        httpRequest(`${process.env.REACT_APP_API_URI}/vehicles`)
            .then(({data}) => {
                setModels(data);
            })
            .catch(e => {
                console.error(e);
            })
            .finally(() => setLoading(false));
    }, []);

    const changeListener = (e) => {
        const {name, value} = e.target;
        const featureType = e.target.getAttribute('data-type');

        if (featureType === 'features') {
            setFeature(prevState => ({
                ...prevState,
                [name]: value,
                type: featureType
            }));
        } else if (featureType === 'techFeatures') {
            setTechFeature(prevState => ({
                ...prevState,
                [name]: value,
                type: featureType
            }));
        } else {
            setForm(prevState => (
                {
                    ...prevState,
                    [name]: value
                }
            ));
        }
    };


    const saveFeature = () => {

        if (feature.type) {
            const newFeatures = form.features;
            newFeatures[feature.title] = feature.desc;
            setForm(prevState => ({
                ...prevState,
                features: newFeatures
            }));
            setFeature({title: '', desc: ''});
        }

        if (techFeature.type) {
            const newFeatures = form.techFeatures;
            newFeatures[techFeature.title] = techFeature.desc;
            setForm(prevState => ({
                ...prevState,
                techFeatures: newFeatures
            }));
            setTechFeature({title: '', desc: ''});
        }
    };

    const formIsInvalid = () => {
        let anyError = false;
        for (let key in form) {
            if (!form[key] && key !== 'errors') {
                const newErrors = Object.assign(form.errors);
                newErrors[key] = {msg: 'Invalid value'};

                setForm(prevState => ({
                    ...prevState,
                    errors: newErrors
                }));

                anyError = true;
            } else if (!Object.keys(form[key]).length) {
                const newErrors = Object.assign(form.errors);
                newErrors[key] = {msg: 'Invalid value'};

                setForm(prevState => ({
                    ...prevState,
                    errors: newErrors
                }));

                anyError = true;
            } else {
                const newErrors = Object.assign(form.errors);
                newErrors[key] = null;

                setForm(prevState => ({
                    ...prevState,
                    errors: newErrors
                }));
            }
        }

        return anyError;
    };

    const createVariant = (e) => {
        e.preventDefault();
        // Stop if data invalid
        if (formIsInvalid()) return;

        // Set blocker
        setLoading(true);

        const data = {
            id: form.id,
            variant: {
                name: form.name,
                techFeatures: form.techFeatures,
                features: form.features
            }
        };
        // Request
        httpRequest(`${process.env.REACT_APP_API_URI}/vehicles/variant/add`, HTTP_METHODS.POST, data)
            .then((data) => {
                bake('Variant created', '#0275d8');
                props.history.push(`/models/${form.id}`);
            })
            .catch(err => {
                if (err.response.status === 422) {
                    const errors = err.response.data.errors;
                    setForm(prevState => (
                        {
                            ...prevState,
                            errors
                        }
                    ));
                    return bake('Invalid input', '#ff0000');
                }

                console.error(err);
                const msg = err.hasOwnProperty('response') ? err.response.data : err;
                bake(msg, '#ff0000');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="container-fluid mt--6">
            {isLoading && <UiBlock/>}
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-header border-0">
                            <h3 className="mb-0">Car Model Variant (SFX)</h3>
                        </div>
                        <div className="card-body">
                            <form onSubmit={createVariant}>
                                <h6 className="heading-small text-muted mb-4">Variant information</h6>
                                <div className="pl-lg-4">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-username">Model</label>
                                                <select name="id" className="form-control" onChange={changeListener}>
                                                    <option value="#" disabled selected/>
                                                    {models.map(model => <option value={model.id}
                                                                                 selected={form.id === model.id}
                                                                                 key={model.id}>{model.name}</option>)}
                                                </select>
                                                {form.errors.id &&
                                                <small className="text-danger">{form.errors.id.msg}</small>}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-control-label"
                                                       htmlFor="input-username">Name</label>
                                                <input type="text" className="form-control" name="name"
                                                       onChange={changeListener}
                                                       placeholder="Camry SFX 03." value={form.name}/>
                                                {form.errors.name &&
                                                <small className="text-danger">{form.errors.name.msg}</small>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row mb-3">
                                                <div className="col-3">
                                                    <label className="form-control-label"
                                                           htmlFor="input-username">Engine & Powertrain Specs</label>
                                                </div>
                                                <div className="col">
                                                    {form.errors.features &&
                                                    <small className="text-danger">{form.errors.features.msg}</small>}
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-5">
                                                    <input type="text" data-type="features" value={feature.title}
                                                           onChange={changeListener}
                                                           name={`title`} placeholder={"Title"}
                                                           className="form-control"/>
                                                </div>
                                                <div className="col-5">
                                                    <input type="text" data-type="features" value={feature.desc}
                                                           onChange={changeListener} placeholder={"Description"}
                                                           name={`desc`}
                                                           className="form-control"/>
                                                </div>
                                                <div className="col text-right">
                                                    <button type="button" className="btn btn-primary w-100"
                                                            onClick={saveFeature}><i
                                                        className="fa fa-plus-circle"/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <table className="table table-condensed table-bordered">
                                                            <thead>
                                                            <tr>
                                                                <th className="w-50">Title</th>
                                                                <th className="w-50">Description</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                Object.entries(form.features).map((feature, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>{feature[0]}</td>
                                                                            <td>{feature[1]}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row mb-3">
                                                <div className="col-6">
                                                    <label className="form-control-label"
                                                           htmlFor="input-username">Creature Comfort & Tech
                                                        Features</label>
                                                </div>
                                                <div className="col">
                                                    {form.errors.techFeatures &&
                                                    <small
                                                        className="text-danger">{form.errors.techFeatures.msg}</small>}
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-5">
                                                    <input type="text" data-type="techFeatures"
                                                           value={techFeature.title}
                                                           onChange={changeListener}
                                                           name={`title`} placeholder={"Title"}
                                                           className="form-control"/>
                                                </div>
                                                <div className="col-5">
                                                    <input type="text" data-type="techFeatures" value={techFeature.desc}
                                                           onChange={changeListener} placeholder={"Description"}
                                                           name={`desc`}
                                                           className="form-control"/>
                                                </div>
                                                <div className="col text-right">
                                                    <button type="button" className="btn btn-primary w-100"
                                                            onClick={saveFeature}><i
                                                        className="fa fa-plus-circle"/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <table className="table table-condensed table-bordered">
                                                            <thead>
                                                            <tr>
                                                                <th className="w-50">Title</th>
                                                                <th className="w-50">Description</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                Object.entries(form.techFeatures).map((feature, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>{feature[0]}</td>
                                                                            <td>{feature[1]}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <button className="btn btn-primary">Save</button>
                                            {form.errors.variant &&
                                            <small className="text-danger">Invalid input, please fill all details
                                                required.</small>}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}