import Toastify from 'toastify-js';

export const bake = (message, color)=>{
    Toastify({
        text: message,
        duration: 3000,
        close: true,
        gravity: "bottom", // `top` or `bottom`
        position: 'right', // `left`, `center` or `right`
        backgroundColor: color,
        // backgroundColor: "linear-gradient(to right, #, #ff0000)",
        stopOnFocus: true, // Prevents dismissing of toast on hover
    }).showToast();
};