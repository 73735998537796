import Axios from "axios";

Axios.interceptors.request.use(
    (config) => {
        let jwtToken = localStorage.getItem('token') || '';
        config.headers['Authorization'] = "Bearer " + jwtToken;
        return config;
    },
    (error) => {
        console.error({error});
        return Promise.reject(error);
    }
);

// Response interceptor
Axios.interceptors.response.use(
    (config) => (config),
    (error) => {

        // If network error catch it because no message
        if (error.message === 'Network Error') {
            return Promise.reject(error.message);
        } else if (error.hasOwnProperty('response') && error.response.status === 401) {
            // Redirect to login if no authorized
            localStorage.removeItem('token');
            setTimeout(() => {
                window.location.href = '/login';
            }, 3000);

            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    });

export const HTTP_METHODS = Object.freeze(
    {
        'POST': 'POST',
        'GET': 'GET',
        'PUT': 'PUT',
        'PATCH': 'PATCH',
        'DELETE': 'DELETE'
    }
);

export const httpRequest = (url, method, data = undefined, headers = undefined) => {
    return Axios({
        method,
        url,
        data,
        headers
    });
};
