// Views
import Index from './Views/Index/Index';
import Messages from "./Views/Messages/Messages";
import Login from "./Views/Login/Login";
import Message from "./Views/Messages/_id";
import Bookings from './Views/Bookings/Bookings';
import _404 from "./Views/Errors/Error404";
import EventCreate from "./Views/Events/Create";
import Events from './Views/Events/Events';
import Event from './Views/Events/_id';
import EventEdit from './Views/Events/Edit';
import Users from './Views/Users/Index';
import User from './Views/Users/_id';
import UserCreate from './Views/Users/Create';
import UserUpdate from './Views/Users/Update';
import VehicleCreate from "./Views/Models/Create";
import VehicleUpdate from "./Views/Models/Update";
import Models from "./Views/Models/Models";
import Model from "./Views/Models/_id";
import VariantCreate from './Views/Models/Variants/Create';
import AddMedia from './Views/Models/Media/Add';

// Layouts
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";
import NetworkError from "./Views/Errors/NetworkError";

export const routes = [
    {
        path: '/clients/messages',
        component: Messages,
        title: 'Messages',
        layout: MainLayout,
        private: true
    },
    {
        path: '/clients/messages/:id',
        component: Message,
        title: 'Message',
        layout: MainLayout,
        private: true
    },
    {
        path: '/login',
        component: Login,
        title: 'Login',
        layout: AuthLayout,
        private: false
    },
    {
        path: '/',
        component: Index,
        title: 'Dashboard',
        layout: MainLayout,
        private: true
    },
    {
        path: '/clients/bookings',
        component: Bookings,
        title: 'Service Appointments',
        layout: MainLayout,
        private: true,
    },
    {
        path: '/404',
        component: _404,
        title: 'Not Found',
        layout: MainLayout,
        private: true,
    },
    {
        path: '/errors/network',
        component: NetworkError,
        title: 'Network Error',
        layout: MainLayout,
        private: true,
    },
    {
        path: '/events/create',
        component: EventCreate,
        title: 'New MAS Event',
        layout: MainLayout,
        private: true
    },
    {
        path: '/events',
        component: Events,
        title: 'MAS Events',
        layout: MainLayout,
        private: true
    },
    {
        path: '/events/:id',
        component: Event,
        title: 'Event',
        layout: MainLayout,
        private: true
    },
    {
        path: '/events/edit/:id',
        component: EventEdit,
        title: 'Edit Event',
        layout: MainLayout,
        private: true
    },
    {
        path: '/users',
        component: Users,
        title: 'System Users',
        layout: MainLayout,
        private: true
    },
    {
        path: '/users/create',
        component: UserCreate,
        title: 'Create User',
        layout: MainLayout,
        private: true
    },
    {
        path: '/users/edit/:id',
        component: UserUpdate,
        title: 'Edit System User',
        layout: MainLayout,
        private: true
    },
    {
        path: '/users/:id',
        component: User,
        title: 'System User',
        layout: MainLayout,
        private: true
    },
    {
        path: '/models',
        component: Models,
        title: 'Models Collection',
        layout: MainLayout,
        private: true
    },
    {
        path: '/models/create',
        component: VehicleCreate,
        title: 'Create Car Model',
        layout: MainLayout,
        private: true
    },
    {
        path: '/models/variants/create',
        component: VariantCreate,
        title: 'Create Variant',
        layout: MainLayout,
        private: true
    },
    {
        path: '/models/media/add/:id',
        component: AddMedia,
        title: 'Add Media',
        layout: MainLayout,
        private: true
    },
    {
        path: '/models/edit/:id',
        component: VehicleUpdate,
        title: 'Update Car Model',
        layout: MainLayout,
        private: true
    },
    {
        path: '/models/:id',
        component: Model,
        title: 'Toyota Model',
        layout: MainLayout,
        private: true
    },
    {
        path: '*',
        component: _404,
        title: 'Not Found',
        layout: AuthLayout,
        private: false,
    },
];