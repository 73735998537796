import React from "react";
import SideNavBar from "../Components/Dashboard/SideNavBar";
import UpperNavBar from "../Components/Dashboard/UpperNavBar";
import Header from "../Components/Dashboard/Header";
import {connect} from "react-redux";
import {toggleSideNav} from "../store/actions";

const MainLayout = ({title, children, pathname, isOpen, dispatch}) => {
    React.useEffect(() => document.body.classList.remove('bg-default'));
    const closeNav = () => {
        if (isOpen) {
            dispatch(toggleSideNav());
        }
    };

    return (
        <div>
            <SideNavBar/>
            <div className="main-content" id="panel" onClick={closeNav}>
                <UpperNavBar/>
                <Header title={title} pathname={pathname}/>
                {children}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isOpen: state.dom.sideNavBar
    }
};

export default connect(mapStateToProps, null)(MainLayout);