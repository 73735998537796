import React from 'react';
import logo from '../../assets/images/224x58.png';

class Navbar extends React.Component {
    render() {
        return (
            <nav id="navbar-main"
                 className="navbar navbar-horizontal navbar-transparent navbar-main navbar-expand-lg navbar-light">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src={logo} alt="logo"/>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse navbar-custom-collapse collapse" id="navbar-collapse">
                        <div className="navbar-collapse-header">
                            <div className="row">
                                <div className="col-6 collapse-brand">
                                    <a href="dashboard.html">
                                        <img src={logo} alt="logo"/>
                                    </a>
                                </div>
                                <div className="col-6 collapse-close">
                                    <button
                                        type="button" className="navbar-toggler" data-toggle="collapse"
                                        data-target="#navbar-collapse" aria-controls="navbar-collapse"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                        <span/>
                                        <span/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navbar;