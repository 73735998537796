import React from "react";
import {HTTP_METHODS, httpRequest} from "../../services/httpService";
import {bake} from "../../helpers/toaster";
import Table from "../../Components/Dashboard/Table";
import Loading from "../../Components/Dashboard/Loading";

const Bookings = (props) => {
        const [bookings, setBookings] = React.useState([]);
        const [isLoading, setLoading] = React.useState(true);

        React.useEffect(() => {
            httpRequest(`${process.env.REACT_APP_API_URI}/service-appointments/`, HTTP_METHODS.GET)
                .then(({data}) => {
                    setBookings(data);
                })
                .catch(err => {
                    const msg = err.response ? err.response.data : err;
                    bake(msg, '#ff0000');
                })
                .finally(() => setLoading(false));
        }, []);

        const columns = React.useMemo(
            () => [
                {
                    Header: 'Name',
                    accessor: 'name',
                },
                {
                    Header: 'Phone',
                    accessor: 'phone',
                },
                {
                    Header: 'Model',
                    accessor: 'model',
                },
                {
                    Header: 'Date',
                    accessor: 'datetime',
                },
            ],
            []);

        return (
            <div className="container-fluid mt--6">
                <div className="row">
                    <div className="col">
                        <div className="card">
                            {isLoading && (
                                <div className="text-center my-auto">
                                    <Loading/>
                                </div>
                            )}
                            {!isLoading && (
                                <>
                                    <div className="card-header border-0">
                                        <h3 className="mb-0">Customer Bookings</h3>
                                        <small>Schedule Service</small>
                                    </div>
                                    <div className="table-responsive">
                                        <Table columns={columns} data={bookings}/>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
;

export default Bookings;