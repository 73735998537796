import React from "react";
import Navbar from "../Components/Auth/Navbar";
import Footer from "../Components/Auth/Footer";

const AuthLayout = (props) => {
    React.useEffect(() => document.body.classList.add('bg-default'));
    return (
        <div>
            <Navbar/>
            {props.children}
            <Footer/>
        </div>
    );
};

export default AuthLayout;