import React from "react";
import Loading from "../../Components/Dashboard/Loading";
import Table from "../../Components/Dashboard/Table";
import {HTTP_METHODS, httpRequest} from "../../services/httpService";
import {bake} from "../../helpers/toaster";
import {Link} from "react-router-dom";

export default (props) => {
    const [loading, setLoading] = React.useState(true);
    const [users, setUsers] = React.useState([]);
    const columns = React.useMemo(() => [
        {
            Header: 'Name',
            accessor: (user => `${user.firstName} ${user.lastName}`)
        },
        {
            Header: 'Title',
            accessor: 'title'
        },
        {
            Header: 'Status',
            accessor: ({status}) => (
                <h4><span className={`badge ${status === 'Active' ? 'badge-success' : 'badge-danger'}`}>{status}</span>
                </h4>
            )
        },
        {
            Header: 'Role',
            accessor: ({isAdmin}) => (
                <h4><span
                    className={`badge ${isAdmin ? 'badge-primary' : 'badge-info'}`}>{isAdmin ? 'Admin' : 'User'}</span>
                </h4>
            )
        },
        {
            Header: 'View',
            accessor: (user => (
                <Link to={`/users/${user.id}`} className="btn btn-primary">View</Link>
            ))
        },
    ], []);

    React.useEffect(() => {
        httpRequest(`${process.env.REACT_APP_API_URI}/users`, HTTP_METHODS.GET)
            .then(({data}) => {
                setUsers(data);
            })
            .catch(e => {
                console.error(e);
                const msg = e.response ? e.response.data : e;
                bake(msg, '#ff0000');
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);


    return (
        <>
            <div className="container-fluid mt--6">
                <div className="row">
                    <div className="col">
                        <div className="card">
                            {loading &&
                            (
                                <div className="text-center my-auto">
                                    <Loading/>
                                </div>
                            )}
                            {!loading &&
                            (<React.Fragment>
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col-8">
                                            <h3 className="mb-0">MAS Motors LLC. Event</h3>
                                        </div>
                                        <div className="col text-right">
                                            <Link to="/users/create" className="btn btn-primary">Create</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Table data={users} columns={columns}/>
                                </div>
                            </React.Fragment>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}