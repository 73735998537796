import React from "react";
import Avatar from 'react-avatar';
import {useHistory} from 'react-router-dom';
import {data} from '../../helpers/jwt';
import {connect} from 'react-redux';
import {toggleSideNav} from "../../store/actions";

const UpperNavBar = ({isActive, dispatch}) => {
    const _user = data(localStorage.getItem('token'));
    const history = useHistory();

    const logout = () => {
        // Remove token from storage and redirect to login
        localStorage.removeItem('token');
        history.push('/login')
    };

    const toggleSideNave = (e) => {
        e.stopPropagation(); // Fix for click event propagating the tree
        dispatch(toggleSideNav());
    };

    return (
        <nav className="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <div className="container-fluid">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    {/*Navbar links*/}
                    <ul className="navbar-nav align-items-center  ml-md-auto ">
                        <li className="nav-item d-xl-none">
                            {/*Sidenav toggler*/}
                            <div className="pr-3 sidenav-toggler sidenav-toggler-dark active" data-action="sidenav-pin"
                                 data-active="false"
                                 onClick={toggleSideNave}
                                 data-target="#sidenav-main">
                                <div className="sidenav-toggler-inner">
                                    <i className="sidenav-toggler-line"/>
                                    <i className="sidenav-toggler-line"/>
                                    <i className="sidenav-toggler-line"/>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul className="navbar-nav align-items-center  ml-auto ml-md-0 ">
                        <li className="nav-item dropdown">
                            <a rel="noopener noreferrer" className="nav-link pr-0" href="/" role="button"
                               data-toggle="dropdown" aria-haspopup="true"
                               aria-expanded="false">
                                <div className="media align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <Avatar name={`${_user.name}`} size={40} round="20px"/>
                  </span>
                                    <div className="media-body  ml-2  d-none d-lg-block">
                                        <span
                                            className="mb-0 text-sm  font-weight-bold">{`${_user.name}`}</span>
                                    </div>
                                </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <a rel="noopener noreferrer" href="#!" className="dropdown-item"
                                   onClick={(e) => logout()}>
                                    <i className="fa fa-door-open"/>
                                    <span>Logout</span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
};


export default connect()(UpperNavBar);